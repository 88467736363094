import { Suspense, type PropsWithChildren } from "react";
import {
	Navigate,
	Outlet,
	RouterProvider,
	createHashRouter,
} from "react-router-dom";
import { NamedRoute } from "../components/utils/NamedRoutes";
import { useAppSelector } from "../hooks/redux-hooks";
import ForgotPassword from "../pages/ForgotPassword";
import Intro from "../pages/Intro";
import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";
import JointBorrowerHome from "../pages/JointBorrowerHome";
import JointIntro from "../pages/JointIntro";
import AcceptInvite from "../pages/AcceptInvite";
import Home from "../pages/Home";
import GettingStarted from "../pages/GettingStarted";
import { useAuthLock } from "../hooks/use-auth-context";
import { useApplicationPolling } from "../hooks/use-hydrate-application-state";
import { selectIsLoggedIn, selectIsLoggedInWithApplication } from "../store/slices/auth-slice";
import useTrackLocation from "../hooks/use-track-location";
import { useAuth0Login } from "../hooks/use-auth0-login";
import { Verification } from "../pages/Verification";
import { AcceptApplicantInvite } from "../pages/AcceptApplicantInvite";
import { WelcomeBackWrapper } from "../pages/WelcomeBackWrapper";
import { AuthCallback } from "../pages/AuthCallback";
import { useLocationApplicationId } from "../hooks/origination/use-location-application-id";
import { Loader } from "../components/ui/loader/Loader";
import { useAccessTokenCheck } from "../hooks/origination/use-access-token-check";
import { AuthLanding } from "../pages/AuthLanding";
import { NotAGoodFit } from "../pages/NotAGoodFit";

const ProtectedRoute = () => {
	const isLoggedIn = useAppSelector(selectIsLoggedIn);
	useTrackLocation();
	return (
		<Suspense fallback={<Loader />}>
			{isLoggedIn ? <Outlet /> : <Navigate to={NamedRoute.login} replace />}
		</Suspense>
	);
};

const AnonymousRoute = ({ children, ignoreLoggedIn = false }: PropsWithChildren<{ ignoreLoggedIn?: boolean }>) => {
	const { isAuthLocked } = useAuthLock();
	const isLoggedInWithApplication = useAppSelector(selectIsLoggedInWithApplication);
	useTrackLocation();

	return (
		<Suspense fallback={<Loader />}>
			{
				isLoggedInWithApplication && !isAuthLocked && !ignoreLoggedIn ? (
					<Navigate to={NamedRoute.welcome} replace />
				) : (
					children
				)
			}
		</Suspense>
	);
};

const ApplicationIDRoute = ({ children, redirect = NamedRoute.welcome }: PropsWithChildren<{ redirect?: string }>) => {
	const applicationId = useLocationApplicationId();
	if (!applicationId) {
		return <Navigate to={redirect} replace />;
	}
	return children;
}

const router = createHashRouter([
	{
		path: "/app",
		element: <ProtectedRoute />,
		children: [
			{ path: NamedRoute.welcome, element: <WelcomeBackWrapper /> },
			{ path: NamedRoute.verification, element: (
				<ApplicationIDRoute>
					<Verification />
				</ApplicationIDRoute>
			) },
			// Add more authenticated routes here
		],
	},
	{
		path: NamedRoute.intro,
		element: (
			<AnonymousRoute>
				<Intro />
			</AnonymousRoute>
		),
	},
	{
		path: NamedRoute.gettingStarted,
		element: (
			<Suspense fallback={<Loader />}>
				<GettingStarted />
			</Suspense>
		),
	},
	{
		path: NamedRoute.home,
		element: <ProtectedRoute />,
		children: [{ path: "", element: <Home /> }],
	},
	{
		path: NamedRoute.jointBorrowerHome,
		element: <ProtectedRoute />,
		children: [{ path: "", element: <JointBorrowerHome /> }],
	},
	{
		path: NamedRoute.jointBorrowerIntro,
		element: <ProtectedRoute />,
		children: [{ path: "", element: <JointIntro /> }],
	},
	{
		path: NamedRoute.notAGoodFit,
		element: <ProtectedRoute />,
		children: [{ path: "", element: <NotAGoodFit /> }],
	},
	{
		path: NamedRoute.login,
		element: (
			<AnonymousRoute ignoreLoggedIn={true}>
				<Login />
			</AnonymousRoute>
		),
	},
	{
		path: NamedRoute.forgotPassword,
		element: (
			<AnonymousRoute ignoreLoggedIn={true}>
				<ForgotPassword />
			</AnonymousRoute>
		),
	},
	{
		path: NamedRoute.resetPassword,
		element: (
			<AnonymousRoute ignoreLoggedIn={true}>
				<ResetPassword />
			</AnonymousRoute>
		),
	},
	{
		path: NamedRoute.acceptInvite,
		element: (
			<AnonymousRoute ignoreLoggedIn={true}>
				<AcceptInvite />
			</AnonymousRoute>
		),
	},
	{
		path: NamedRoute.acceptApplicantInvite,
		element: (
			<AnonymousRoute ignoreLoggedIn={true}>
				<AcceptApplicantInvite />
			</AnonymousRoute>
		),
	},
	{
		path: NamedRoute.authCallback,
		element: <AuthCallback />
	},
	{
		path: NamedRoute.authLanding,
		element: (
			<AnonymousRoute ignoreLoggedIn={false}>
				<AuthLanding />
			</AnonymousRoute>
		)
	}
]);

export const RoutesSelector = () => {
	useApplicationPolling();
	useAuth0Login();
	useAccessTokenCheck();

	return <RouterProvider router={router} />;
};
